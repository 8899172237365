

.login-logo {
  width: 75%;
  height: 20%;
  margin: 0 18% 30px 18%;
}

.login-form {
  label {
    font-size: 14px;
  }

  input {
    border: none;
    border-bottom: 1px solid $secondary-color;
  }
}

.filter-bar {
  display: inline-block;
  cursor: pointer;
  margin: 0rem 0.5rem;

  .filters {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 0.25rem;

    &-label {
      color: #c3c3c3;
    }
  }

  .fa {
    color: #4d4d4d;
    margin-right: 0.25rem;
  }
}

.btn-add-new {
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1;
  box-shadow: 0 0 50px 0 rgba(110, 110, 110, 0.17);
}

.table-checkbox,
.filter-checkbox {
  svg {
    font-size: 14px;
  }
}

.filter-container {
  position: absolute;
  top: 70px;
  right: 5%;
  display: inline-block;
}

// Here you can add other styles

.main {
  background-color: #ffffff !important;
  padding-left: 1rem;
}

input[class=form-control] {
  border: none;
  border-bottom: 1px solid #c3c3c3;
}

* {
  font-family: Avenir;
}

.btn-primary {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  border-radius: 4em !important;
  font-family: 'Avenir-Black' !important;
  color: #ffffff !important;

  &:hover {
    background-color: darken($primary-color, 15%) !important;
    border-color: darken($primary-color, 15%) !important;
  }
}

.btn-secondary {
  background-color: #c3c3c3;
  border-color: #c3c3c3;
  border-radius: 4em !important;
  font-family: 'Avenir-Black';
  color: #ffffff !important;

  &:hover {
    background-color: darken(#c3c3c3, 15%);
    border-color: darken(#c3c3c3, 15%);
  }
}

.avenir-black-primary {
  font-family: 'Avenir-Black';
  color: $primary-color;
}

.navbar-item {
  color: #000000
}

.navbar-item:hover {
  color: #007bff
}

.nav-link1:hover::after{
  content: '';
  display: block;
  border-bottom: 5px solid #007bff;
  width: 100%;
  margin: auto;
}

.nav-link:active::after{
  content: '';
  display: block;
  border-bottom: 5px solid #007bff;
  width: 100%;
  margin: auto;
}


.actives::after{
  content: '';
  display: block;
  border-bottom: 5px solid #007bff;
  width: 100%;
  margin: auto;
  color: #007bff
}

.actives>h6{
 
  color: #007bff
}

.modal-body {
  padding: 1.5rem 3rem !important;
}

.modal-header {
  padding: 0.5rem 1rem !important;

  .close {
    padding: 1.25rem 1.5rem 1rem 1rem !important;
  }
}

.close {
  // color: $primary-color !important;
  opacity: 1 !important;
  font-size: 1.75rem !important;
}

.table {
  margin-bottom: 0 !important;
}

@import "sidebar";
@import "card";
@import "login";

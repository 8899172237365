.card {
  border: 0px !important;
  box-shadow: 0px 0px 0px 0px transparent !important;
  border-radius: 5px !important;
  background-color: #ffffff !important;
}

.card-header {
  background-color: #ffffff !important;
}

.card-accent {
  border-left-color: blue !important;
  border-left: 2px !important;
}

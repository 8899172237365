// Variable overrides

$primary-color: #007bff;
$secondary-color: #00b1ff;
$sidebar-main: #4d4d4d;
$sidebar-secondary: #c3c3c3;

@font-face {
  font-family: 'Avenir';
  src: url('assets/fonts/avenir_ff/AvenirLTStd-Book.otf');
}

@font-face {
  font-family: 'Avenir-Black';
  src: url('assets/fonts/avenir_ff/AvenirLTStd-Black.otf');
}

@font-face {
  font-family: 'Avenir-Roman';
  src: url('assets/fonts/avenir_ff/AvenirLTStd-Roman.otf');
}

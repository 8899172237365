.sidebar {
  background-color: #ffffff !important;
  box-shadow: 0 10px 10px 0 rgba(142, 142, 142, 0.16);

  .sidebar-nav {
    overflow-y: hidden;
  }

  .nav-item {
    // margin-left: 15px !important;
  }

  .nav-link {
    color: $sidebar-main !important;
    font-family: 'Avenir-Roman';

    &.active {
      background: #ffffff !important;
      color: $primary-color !important;
      font-weight: bold !important;
    }

    &:hover {
      background: $primary-color !important;
      color: #fff !important;
      font-weight: bold !important;

      .nav-icon {
        color: #ffffff !important;
      }
    }
  }

  .nav-dropdown.open {
    background: #ffffff !important;
  }
}

.sidebar-minimizer {
  height: 50px !important;
  background: #ffffff !important;

  &:hover {
    background: $primary-color !important;
  }
}

.sidebar-minimized {
  .sidebar {
    .sidebar-minimizer {
      position: relative !important;
      // top: 0 !important;
      // width: 5rem !important;
      height: 50px !important;
      // background-color: $primary-color !important;
    }

    .nav-dropdown-items {
      .nav-item {
        width: 200px !important;

        .nav-link {
          width: 200px !important;
          padding-left: 10px !important;
        }
      }
    }

    .nav {
      >.nav-dropdown {
        >.nav-dropdown-items {
          background: #fff !important;
        }
      }
    }

    .nav-link {
      color: $sidebar-main !important;
      background-color: #fff !important;

      .nav-icon {
        color: $sidebar-main !important;
      }

      &:hover {
        color: #fff !important;
        background-color: $primary-color !important;

        .nav-icon {
          color: #fff !important;
          background-color: $primary-color !important;
        }
      }
    }
  }
}
